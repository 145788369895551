/* eslint-disable consistent-return */
/* eslint-disable no-useless-catch */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-continue */
/* eslint-disable no-unused-expressions */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-plusplus */
import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from "react";
import { useSelector } from "react-redux";
import {
  STATE,
  STATUS,
  TABLE,
  DATE_DEFAULT_VALUE,
  MESSAGE,
  SUCCESS_ICON,
  ERROR_ICON,
  USER_ICON,
  WRONG_DATA_INITIALS,
  UNAUTHORIZED_ERROR_CODE,
  CHECK,
  EVENTS
} from "../constants/common.constants";
import {
  useAssignIncident,
  useGetIncidents,
  useGetNextIncidentStatus,
  useGetIncidentCatalog,
  useUpdateIncident,
  useGetAllIncidents
} from "../api/incidents";
import useTableInstance from "../components/table/useTableInstance";
import useIncidentsTableColumns from "../incident-list/components/useIncidentsTableColumns";
import useCatalog from "../organizations/organization/useCatalog";
import FormatDate from "../formatDate/formatDate";
import IncidentListfiltersContext from "./incident-list-filters.context";
import { incidentsListParametersMapper } from "../mappers/incidentListMappers";
import ModalContext from "./modal.context";
import SnackbarContext from "./snackbar.context";
import { getInitials } from "../utils/string.utils";
import SessionContext from "./session.context";
import useIdle from "../hooks/useIdle";
import Permissions from "../permissions/permissions";
import {
  IAnyPropertyNameAndStringValue,
  IProvider
} from "../types/common.types";
import {
  IIncident,
  IIncidentCatalog,
  IIncidentListContext
} from "./types/incident.list.types";
import exportToCSV from "../utils/exportToCSV";

const IncidentListContext = createContext<IIncidentListContext>(
  {} as IIncidentListContext
);

export function IncidentListProvider({ children }: IProvider) {
  const [incidentCatalog, setIncidentCatalog] = useState<IIncidentCatalog>(
    {} as IIncidentCatalog
  );
  const [loading, setLoading] = useState(false);
  const [loadingDownloadData, setLoadingDownloadData] = useState(false);
  const [incidents, setIncidents] = useState<IIncident[]>([]);
  const [selectedIncidents, setSelectedIncidents] = useState(new Map());
  const [pages, setPages] = useState({
    current: 1,
    total: 0
  });
  const [rows, setRows] = useState({
    start: 0,
    finish: 0,
    total: 0
  });

  const getIncidentsInProgress = useRef(false);

  const { isMdrRole, isProviderRole, handleLogOut } =
    useContext(SessionContext);
  const localUser = useSelector((state: any) => state.user.profile);
  const assign = useAssignIncident();
  const get = useGetIncidents();
  const getAll = useGetAllIncidents();
  const getNextStatus = useGetNextIncidentStatus();
  const getIncidentCatalog = useGetIncidentCatalog();
  const { catalog } = useCatalog();
  const updateIncident = useUpdateIncident();
  const checkUserActivity = useIdle();
  const { Incidents: permissions } = Permissions();

  const filters = useContext(IncidentListfiltersContext);
  const { setAsyncLoading, closeModal, showModal } = useContext(ModalContext);
  const { showSnackbar } = useContext(SnackbarContext);
  const {
    currentFilters,
    filtersChanged,
    setFiltersChanged,
    forceCloseFilters,
    searchText
  } = useContext(IncidentListfiltersContext);

  const columns = useIncidentsTableColumns();
  const tableRows = useMemo(
    () => (loading ? [] : incidents),
    [incidents, loading]
  );
  const tableColumns = useMemo(() => {
    if (isMdrRole) return columns.mdrUsersStructure;

    if (isProviderRole) return columns.providerStructure;

    return columns.clientStructure;
  }, [tableRows]); // eslint-disable-line react-hooks/exhaustive-deps
  const table = useTableInstance(tableColumns, tableRows);

  const handlers = useMemo(() => {
    if (!Reflect.has(catalog, "incident_handlers")) {
      return {};
    }

    return catalog.incident_handlers;
  }, [catalog]);

  const headerAvailable = useMemo(
    () => permissions.sections.info.header.canSee(localUser.role),
    [localUser.role, permissions.sections.info.header]
  );

  const closeReasons = useMemo(
    () =>
      Reflect.has(incidentCatalog, "close_reason")
        ? Object.entries(incidentCatalog.close_reason)
        : [],
    [incidentCatalog]
  );

  const areRowsSelected = useMemo(
    () => !!selectedIncidents.size,
    [selectedIncidents]
  );

  const showCloseAction = useMemo(
    () =>
      permissions.sections.actionBar.close.canSee(localUser.role) &&
      areRowsSelected,
    [areRowsSelected, localUser.role, permissions.sections.actionBar.close]
  );

  const getNextStatusFromState = useCallback(
    (tableInstance: any, rowIndex: string) => {
      const cellState = tableInstance.getCellState(
        rowIndex,
        TABLE.INCIDENTS.CELLS.MDR_USERS.status.index
      );

      const status =
        Reflect.has(cellState, "data") && Reflect.has(cellState.data, "next");

      return status ? cellState.data.next : null;
    },
    []
  );

  const closeAllowed = useCallback(
    (tableInstance: any, rowIndex: string) => {
      const status = getNextStatusFromState(tableInstance, rowIndex);

      if (!status) {
        return false;
      }

      return (
        Reflect.has(status, "values") &&
        Reflect.has(status.values, STATUS.closed)
      );
    },
    [getNextStatusFromState]
  );

  const onSelectCheck = useCallback(
    (event: any) => {
      const { id, row } = event.detail;

      if (!closeAllowed(table, row)) {
        return;
      }

      setSelectedIncidents((prevState) => {
        const newMap = new Map(prevState);
        newMap.set(id, row);
        return newMap;
      });
    },
    [closeAllowed, table]
  );

  const onUnselectCheck = useCallback((event: any) => {
    const { id } = event.detail;
    setSelectedIncidents((prevState) => {
      const newMap = new Map(prevState);
      newMap.delete(id);
      return newMap;
    });
  }, []);

  const onCheckAllIncidents = useCallback(() => {
    for (let rowIndex = 0; rowIndex < tableRows.length; rowIndex++) {
      const id = table.getCellData(
        rowIndex,
        TABLE.INCIDENTS.CELLS.MDR_USERS.id.accessor
      );
      onSelectCheck({ detail: { id, row: rowIndex } });
    }
    table.selectAllRows();
  }, [onSelectCheck, table, tableRows.length]);

  const uncheckAllIncidents = useCallback(() => {
    setSelectedIncidents(new Map());
    table.unSelectAllRows();
  }, [table]);

  const closeSelectedIncidents = useCallback(
    async (reason: string) => {
      try {
        setAsyncLoading(true);
        let incidentsClosed = 0;
        const response: any[] = [];

        for (const [incident, row] of selectedIncidents.entries()) {
          try {
            const body = {
              status: STATUS.closed,
              close_reason: reason
            };

            // eslint-disable-next-line no-await-in-loop
            const apiResponse = await updateIncident(incident, body);

            const responseData: any = {
              row,
              id: incident,
              status: apiResponse.status,
              severity: apiResponse.severity,
              handler_id: apiResponse.handler_id,
              risk: apiResponse.risk,
              category: apiResponse.category
            };

            incidentsClosed++;
            response.push(responseData);
          } catch (error) {
            response.push({
              id: incident,
              status: STATUS.error,
              message: error
            });
          }
        }

        setIncidents((prevState) => {
          const newData = [...prevState];

          for (const incident of response) {
            if (incident.status !== STATUS.error) {
              const severity = incidentCatalog.severity[incident.severity];
              newData[incident.row].status = incident.status;
              newData[incident.row].handler_name = getInitials(
                handlers[incident.handler_id]
              );
              newData[incident.row].handler_fullname =
                handlers[incident.handler_id];
              newData[incident.row].handler_id = incident.handler_id;
              newData[incident.row].severity = severity;
              newData[incident.row].risk = incident.risk;
              newData[incident.row].category =
                incidentCatalog.risk_category[incident.risk].category[
                  incident.category
                ];
            }
          }
          return newData;
        });

        if (incidentsClosed === selectedIncidents.size) {
          console.group("Incidents closed");
          console.info(
            `Incidents closed as ${incidentCatalog.close_reason[reason]}`
          );
          console.info(response);
          console.groupEnd();
          showSnackbar({
            text: `Incidents closed as ${incidentCatalog.close_reason[reason]}`,
            type: MESSAGE.info,
            icon: SUCCESS_ICON
          });
        } else {
          console.group("Error closing incidents");
          console.error(
            `${
              selectedIncidents.size - incidentsClosed
            } incidents could not be closed`
          );
          console.error(response);
          console.groupEnd();
          showSnackbar({
            text: `${
              selectedIncidents.size - incidentsClosed
            } incidents could not be closed`,
            type: MESSAGE.error,
            icon: ERROR_ICON
          });
        }
        uncheckAllIncidents();
        setAsyncLoading(false);
        closeModal();
      } catch (error) {
        console.error(error);
      }
    },
    [
      setAsyncLoading,
      setIncidents,
      selectedIncidents,
      uncheckAllIncidents,
      closeModal,
      updateIncident,
      incidentCatalog,
      handlers,
      showSnackbar
    ]
  );

  const updateEpoch = useCallback((value: number) => {
    const currentDate = new Date(value * 1000);

    const utcMonth = currentDate.getUTCMonth() + 1;
    const utcDay = currentDate.getUTCDate();
    const utcYear = currentDate.getFullYear();

    const newDate = new Date(value * 1000);

    newDate.setUTCMonth(utcMonth - 1);
    newDate.setUTCDate(utcDay);
    newDate.setUTCFullYear(utcYear);
    newDate.setUTCHours(0, 0, 0, 0);

    return newDate.getTime() / 1000.0;
  }, []);

  const getMdrUsersData = useCallback(
    (incident: IIncident) => {
      const {
        org_name,
        risk,
        severity,
        category,
        title,
        rules,
        hostname,
        id,
        created,
        updated,
        status,
        handler_id,
        handler_name
      } = incident;

      const initials = getInitials(handler_name);

      return {
        org_name,
        risk,
        severity,
        category,
        title,
        rules,
        hostname,
        id,
        created: updateEpoch(created),
        updated: updateEpoch(updated),
        status,
        handler_name: initials === WRONG_DATA_INITIALS ? "" : initials,
        handler_fullname: handler_name,
        handler_id
      };
    },
    [updateEpoch]
  );

  const getClientUsersData = useCallback(
    (incident: IIncident) => {
      const {
        org_name,
        risk,
        severity,
        category,
        title,
        hostname,
        id,
        created,
        updated,
        status
      } = incident;

      return {
        org_name,
        severity,
        category,
        title,
        hostname,
        id,
        created: updateEpoch(created),
        updated: updateEpoch(updated),
        status,
        risk
      };
    },
    [updateEpoch]
  );

  const loadPagingInformation = useCallback((headers: any) => {
    let page = headers.get("x-page");
    page = page ? Number(page) : 1;

    const rowsPerPage = headers.get("x-per-page");

    let totalRows = headers.get("x-total-count");
    totalRows = totalRows ? Number(totalRows) : 0;

    const rowsLastPage = totalRows % rowsPerPage;

    let incidentPages = Math.floor(totalRows / rowsPerPage);
    incidentPages += rowsLastPage ? 1 : 0;

    const rowsStart = totalRows
      ? page === 1
        ? 1
        : (page - 1) * rowsPerPage + 1
      : 0;
    const rowsFinish = totalRows
      ? page < incidentPages
        ? page * rowsPerPage
        : totalRows
      : 0;

    setPages({
      current: page,
      total: incidentPages
    });

    setRows({
      start: rowsStart,
      finish: rowsFinish,
      total: totalRows
    });
  }, []);

  const loadData = useCallback(
    async (apiResponse: any) => {
      const { headers } = apiResponse;
      if (currentFilters?.creationStartDate?.caption === DATE_DEFAULT_VALUE) {
        const creationStartDate = headers.get("creation-start-date");
        if (creationStartDate) {
          const date = new FormatDate(parseInt(creationStartDate, 10), false);
          filters.setCreationStartDateFilter(date.epochDate, date.date);
        }
      }

      loadPagingInformation(headers);

      const jsonData = await apiResponse.json();
      const apiData = jsonData || [];

      const incidentRows = apiData.map((incident: IIncident) => {
        if (isMdrRole) {
          return getMdrUsersData(incident);
        }
        return getClientUsersData(incident);
      });
      setIncidents(incidentRows);
      setLoading(false);
    },
    [
      currentFilters?.creationStartDate?.caption,
      loadPagingInformation,
      filters,
      isMdrRole,
      getClientUsersData,
      getMdrUsersData
    ]
  );

  const load = useCallback(
    async (page = pages.current) => {
      if (getIncidentsInProgress.current) {
        return;
      }
      getIncidentsInProgress.current = true;
      setLoading(true);

      try {
        const response = await get(
          incidentsListParametersMapper(currentFilters, page, searchText)
        );
        loadData(response);
        filters.updateFilters();
        getIncidentsInProgress.current = false;
      } catch (error: any) {
        console.error(
          `Error getting incidents. Status ${error.status}. ${error}`
        );
        setLoading(false);
        getIncidentsInProgress.current = false;
        if (error.status === UNAUTHORIZED_ERROR_CODE) handleLogOut();
      }
    },
    [pages, get, currentFilters, searchText, loadData, filters, handleLogOut]
  );

  const loadDownloadData = useCallback(async () => {
    setLoadingDownloadData(true);

    try {
      const response = await getAll(
        incidentsListParametersMapper(currentFilters, 0, searchText)
      );
      exportToCSV(response, "incidents.csv");
      setLoadingDownloadData(false);
    } catch (error: any) {
      console.error(
        `Error downloading incidents. Status ${error.status}. ${error}`
      );
      setLoadingDownloadData(false);
    }
  }, [getAll, currentFilters, searchText]);

  const loadNextStatus = useCallback(
    async (id: string) => {
      try {
        return await getNextStatus(id);
      } catch (error: any) {
        console.error(
          `Error getting next status of incident ${id} on incidents list. Status ${error.status}. ${error}`
        );
        throw error;
      }
    },
    [getNextStatus]
  );

  const buildNextStatusState = useCallback(
    (status: string, values: null | { values: any } = null) => ({
      type: STATE.status,
      data: {
        next: {
          status,
          ...(values ? { values: { ...values } } : {})
        }
      }
    }),
    []
  );

  const updateStatusOptions = useCallback(async () => {
    for (const [index, row] of table.instance.rows.entries()) {
      const status = getNextStatusFromState(table, index);

      if (status) {
        continue;
      }

      const pendingState = buildNextStatusState(STATUS.pending);

      try {
        table.setCellState(
          index,
          TABLE.INCIDENTS.CELLS.MDR_USERS.status.index,
          pendingState
        );

        const id = row.cells[TABLE.INCIDENTS.CELLS.MDR_USERS.id.index].value;

        const response = await loadNextStatus(id);

        const fulfilledState = buildNextStatusState(
          STATUS.fulfilled,
          response.status
        );

        table.setCellState(
          index,
          TABLE.INCIDENTS.CELLS.MDR_USERS.status.index,
          fulfilledState
        );
      } catch (error) {
        console.error(error);
      }
    }
  }, [table, loadNextStatus, buildNextStatusState, getNextStatusFromState]);

  const handleAssign = useCallback(
    async (eventDetail: any) => {
      const { rowIndex, incidentId, previousHandlerId, handlerId } =
        eventDetail;
      const name = handlers[handlerId];
      const body: any = {
        handler_id: handlerId
      };

      if (!previousHandlerId) {
        body.status = STATUS.claimed;
      }

      try {
        const initials = getInitials(name);
        const response = await assign(incidentId, body);
        setIncidents((prevState) => {
          const newData = [...prevState];
          newData[rowIndex].status = response.status;
          newData[rowIndex].handler_name = initials;
          newData[rowIndex].handler_fullname = name;
          newData[rowIndex].handler_id = handlerId;
          return newData;
        });

        return {
          incidentId,
          name
        };
      } catch (error) {
        throw error;
      }
    },
    [assign, handlers]
  );

  const closeSingle = useCallback(
    async (reason: string, id: string, row: number) => {
      try {
        setAsyncLoading(true);
        const closeReasonsFromCatalog = incidentCatalog.close_reason;
        const body = {
          status: STATUS.closed,
          close_reason: reason
        };
        const response = await updateIncident(id, body);
        setIncidents((prevState) => {
          const newData = [...prevState];
          const severity = incidentCatalog.severity[response.severity];
          newData[row].status = response.status;
          newData[row].handler_name = getInitials(
            handlers[response.handler_id]
          );
          newData[row].handler_fullname = handlers[response.handler_id];
          newData[row].handler_id = response.handler_id;
          newData[row].severity = severity;
          newData[row].risk = response.risk;
          newData[row].category =
            incidentCatalog.risk_category[response.risk].category[
              response.category
            ];
          return newData;
        });
        showSnackbar({
          text: `Incident closed as ${closeReasonsFromCatalog[reason]}`,
          type: MESSAGE.info,
          icon: SUCCESS_ICON
        });
        setAsyncLoading(false);
        closeModal();
      } catch (error: any) {
        console.error(
          `Error closing incident ${id} with reason ${reason}. Status ${error.status}. ${error}`
        );
        showSnackbar({
          text: `Error closing incident. ${error}`,
          type: MESSAGE.error,
          icon: ERROR_ICON
        });
        setAsyncLoading(false);
        closeModal();
      }
    },
    [
      setAsyncLoading,
      incidentCatalog,
      updateIncident,
      showSnackbar,
      closeModal,
      handlers
    ]
  );

  const onClickClose = useCallback(
    (data: any, callBack: any = () => {}) => {
      const { value, row, id } = data;
      const text = `Are you sure you want to close ${
        typeof id === "undefined" && table.instance.selectedFlatRows.length > 1
          ? `these ${table.instance.selectedFlatRows.length} incidents`
          : "this incident"
      } as ${
        closeReasons.filter(
          ([reason]) => reason === value || reason === data
        )[0][1]
      }`;
      showModal({
        title: "Close incident",
        clickAction: () =>
          typeof id === "undefined"
            ? callBack(data)
            : closeSingle(value, id, row),
        actionText: "Yes, close",
        content: <p>{text}</p>
      });
    },
    [
      table.instance.selectedFlatRows.length,
      closeReasons,
      showModal,
      closeSingle
    ]
  );

  const onClickAssign = useCallback(
    (event: any) => {
      event.stopImmediatePropagation();
      handleAssign(event.detail)
        .then((response) => {
          console.log(
            `Incident ${response.incidentId} assigned to ${response.name}`
          );
          showSnackbar({
            text: `Incident assigned to ${response.name}`,
            type: MESSAGE.info,
            icon: USER_ICON
          });
        })
        .catch((error) => {
          const { incidentId, handlerId } = event.detail;
          console.error(
            `Error assigning incident ${incidentId} to ${handlerId}. Status ${error.status}. ${error}`
          );
          showSnackbar({
            text: "Error assigning incident",
            type: MESSAGE.error,
            icon: ERROR_ICON
          });
        });
    },
    [handleAssign, showSnackbar]
  );

  const handleGotoPage = useCallback((page: number) => load(page), [load]);

  const handlePreviousPage = useCallback(
    () => handleGotoPage(pages.current - 1),
    [handleGotoPage, pages]
  );

  const handleNextPage = useCallback(
    () => handleGotoPage(pages.current + 1),
    [handleGotoPage, pages]
  );

  useEffect(() => {
    getIncidentCatalog()
      .then((catalogData) => setIncidentCatalog(catalogData))
      .catch((error) =>
        console.error(
          `Error getting incidents catalog. Status ${error.status}. ${error}`
        )
      );
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!Object.keys(handlers).length) {
      return;
    }

    const state = {
      type: STATE.handlers,
      data: {
        handlers
      }
    };

    // @typescript-eslint/no-unused-vars
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    for (const [index, row] of table.instance.rows.entries()) {
      const assignedState = table.getCellState(
        index,
        TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.index
      );

      !Reflect.has(assignedState, "data") &&
        table.setCellState(
          index,
          TABLE.INCIDENTS.CELLS.MDR_USERS.assigned.index,
          state
        );

      if (isMdrRole)
        !Reflect.has(
          table.getCellState(
            index,
            TABLE.INCIDENTS.CELLS.MDR_USERS.actions.index
          ),
          "data"
        ) &&
          table.setCellState(
            index,
            TABLE.INCIDENTS.CELLS.MDR_USERS.actions.index,
            state
          );
    }
  }, [handlers, table, isMdrRole]);

  useEffect(() => {
    if (!table.instance.rows.length) {
      return;
    }

    if (isMdrRole) {
      updateStatusOptions();
    }
  }, [table.instance.rows, updateStatusOptions, isMdrRole]);

  useEffect(() => {
    setFiltersChanged(false);
    load();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersChanged]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => forceCloseFilters(), []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => checkUserActivity(), []);

  useEffect(() => {
    if (headerAvailable && areRowsSelected)
      if (table.instance.isAllPageRowsSelected)
        columns.setHeaderCheckState(CHECK.checked);
      else columns.setHeaderCheckState(CHECK.indeterminate);
    else columns.setHeaderCheckState(CHECK.unchecked);
  }, [
    headerAvailable,
    columns,
    table.instance.isAllPageRowsSelected,
    areRowsSelected
  ]);

  useEffect(() => {
    if (headerAvailable && tableRows.length) {
      window.removeEventListener(
        EVENTS.checkAllIncidents,
        onCheckAllIncidents,
        true
      );
      window.addEventListener(
        EVENTS.checkAllIncidents,
        onCheckAllIncidents,
        true
      );
    }

    return () => {
      window.removeEventListener(
        EVENTS.checkAllIncidents,
        onCheckAllIncidents,
        true
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRows, table, columns]);

  useEffect(() => {
    if (headerAvailable) {
      window.addEventListener(EVENTS.checkIncident, onSelectCheck);
      window.addEventListener(EVENTS.uncheckIncident, onUnselectCheck);
      window.addEventListener(EVENTS.uncheckAllIncidents, uncheckAllIncidents);
    }

    return () => {
      window.removeEventListener(EVENTS.checkIncident, onSelectCheck);
      window.removeEventListener(EVENTS.uncheckIncident, onUnselectCheck);
      window.removeEventListener(
        EVENTS.uncheckAllIncidents,
        uncheckAllIncidents
      );
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const returnedValue: IIncidentListContext = useMemo(
    () => ({
      columns,
      pages,
      rows,
      table,
      tableRows,
      incidentCatalog,
      handlers,
      loading,
      closeReasons,
      incidents,
      showCloseAction,
      areRowsSelected,
      loadingDownloadData,
      closeAllowed,
      handleAssign,
      handlePreviousPage,
      handleNextPage,
      handleGotoPage,
      onClickAssign,
      updateIncident,
      onClickClose,
      load,
      setIncidents,
      onUnselectCheck,
      onCheckAllIncidents,
      closeSelectedIncidents,
      uncheckAllIncidents,
      onSelectCheck,
      loadDownloadData,
      isLoading: false,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      onAssign: (props: IAnyPropertyNameAndStringValue) => {},
      setFiltersChanged,
      filtersChanged,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      getIncidentStatus: (id: string) => ({})
    }),
    [
      columns,
      pages,
      rows,
      table,
      tableRows,
      incidentCatalog,
      handlers,
      loading,
      closeReasons,
      incidents,
      showCloseAction,
      areRowsSelected,
      loadingDownloadData,
      closeAllowed,
      handleAssign,
      handlePreviousPage,
      handleNextPage,
      handleGotoPage,
      onClickAssign,
      updateIncident,
      onClickClose,
      load,
      setIncidents,
      onUnselectCheck,
      onCheckAllIncidents,
      closeSelectedIncidents,
      uncheckAllIncidents,
      onSelectCheck,
      loadDownloadData,
      setFiltersChanged,
      filtersChanged
    ]
  );

  return (
    <IncidentListContext.Provider value={returnedValue}>
      {children}
    </IncidentListContext.Provider>
  );
}

export default IncidentListContext;
