import { IAnyPropertyNameAndStringValue } from "../types/common.types";

export const getKeyNameByObjectValue = (
  mainObject: any,
  value: number
): string => Object.keys(mainObject)[value];

export const isEmptyObject = (
  obj: IAnyPropertyNameAndStringValue | undefined
): boolean => Boolean(!Object.keys(obj || {}).length);
